<template>
  <main>
    <section>
      <h1>U bent aangemeld</h1>
      <p>U bent aangemeld voor de reservelijst coronavaccinatie. Zodra er een plek beschikbaar komt, krijgt u bericht.</p>
      <p><router-button to="/">Ga terug naar home</router-button></p>
    </section>
  </main>
</template>

<script>
import Session from '@/Infrastracture/Session';
import RouterButton from '../../SharedKernel/Atoms/Buttons/RouterButton.vue';
const LocalSession = new Session();

export default {
  components: { RouterButton },
  created () {
    LocalSession.clear();
    this.$store.dispatch('WaitingListModule/resetData');
  },
}
</script>
