<template>
  <main>
    <form @submit.prevent="submitForm">
      <section>
        <h1>Vul uw contactgegevens in</h1>
        <p>Om u te kunnen uitnodigen voor een coronavaccinatie hebben wij uw geboortedatum en emailadres nodig. Nadat u gevaccineerd bent, verwijderen wij uw gegevens.</p>
      </section>

      <section>
        <div class="form-control">
          <label for="year-of-birth">Vul uw geboortejaar in *</label>
          <div
            class="selectbox"
            id="year-of-birth-select"
          >
            <select
              id="year-of-birth"
              v-model="yearOfBirth"
              @input="inputYearOfBirth"
              autofocus
            >
              <option :value="minimumYear">{{ minimumYear }}</option>
              <option
                v-for="year in years"
                :key="year"
                :value="minimumYear + year"
              >{{ minimumYear + year }}</option>
            </select>
          </div>
          <alert
            mode="error with-icon"
            icon="error"
            v-if="yearOfBirthError"
            role="img"
            aria-label="Foutmelding"
          >{{ yearOfBirthError }}</alert>
        </div>

        <div class="form-control">
          <label for="email-address">E-mailadres *</label>
          <p>Vul uw e-mailadres in en druk op de ‘verstuur code’. U ontvangt dan per email een code die u moet invullen ter controle. Dit doen we zodat uw e-mailadres niet misbruikt wordt.</p>
          <input
            type="email"
            id="email-address"
            placeholder="voorbeeld@provider.nl"
            v-model.trim="emailAddress"
            :class="{ error: emailError }"
            @input="inputEmail"
          />
          <alert
            mode="error with-icon"
            icon="error"
            v-if="emailError"
            role="img"
            aria-label="Foutmelding"
          >{{ emailError }}</alert>
          <alert
            mode="valid with-icon"
            icon="valid"
            v-if="verified"
            role="img"
            aria-label="Geldig"
          >{{ verified }}</alert>
          <base-button
            type="button"
            id="verify-email-address-button"
            :disabled="!isValidEmailAddress"
            @click.native.prevent="displayVerificationForm"
            v-if="!verified"
          >Verstuur code</base-button>
        </div>

        <div
          class="form-control"
          v-if="showVerificationForm"
        >
          <verification-form @verify-code="isVerified"></verification-form>
        </div>

        <div class="form-control">
          <label for="phonenumber">Telefoonnummer</label>
          <input
            type="text"
            id="phonenumber"
            placeholder="06 12345678"
            v-model.trim="phonenumber"
            :class="{ error: phonenumberError }"
            @input="inputPhonenumber"
          />
          <alert
            mode="error with-icon"
            icon="error"
            role="img"
            aria-label="Foutmelding"
            v-if="phonenumberError"
          >{{ phonenumberError }}</alert>
        </div>

      </section>

      <section class="navigation-buttons">
        <router-button
          to="/"
          class="secondary"
        ><span class="icon arrow-head-left"></span> Vorige</router-button>
        <base-button :disabled="existingUser">
          Volgende
          <span class="icon arrow-head-right"></span>
        </base-button>
      </section>
    </form>
  </main>
</template>

<script>
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';
import BaseButton from '@/SharedKernel/Atoms/Buttons/BaseButton.vue';
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';
import VerificationForm from '@/SharedKernel/Molecules/Verifications/Form.vue';

import WaitingList from '@/Infrastracture/WaitingList';
const WaitingListResource = new WaitingList();

export default {
  components: {
    RouterButton,
    BaseButton,
    Alert,
    VerificationForm,
  },

  data () {
    return {
      yearOfBirth: null,
      emailAddress: null,
      zipcode: null,
      minimumYear: 1900,
      currentYear: new Date().getFullYear(),
      phonenumber: '',
      yearOfBirthError: false,
      isValidEmailAddress: false,
      emailError: false,
      phonenumberError: false,
      showVerificationForm: false,
      verified: false,
      existingUser: false,
    };
  },

  computed: {
    storeYearOfBirth () {
      return this.$store.getters['WaitingListModule/yearOfBirth'];
    },
    storeEmailAddress () {
      return this.$store.getters['WaitingListModule/emailAddress'];
    },
    storeVerifiedEmailAddress () {
      return this.$store.getters['WaitingListModule/verifiedEmailAddress'];
    },
    storePhonenumber () {
      return this.$store.getters['WaitingListModule/phonenumber'];
    },
    years () {
      return this.currentYear - this.minimumYear;
    },
  },

  watch: {
    emailAddress (newVal) {
      if (newVal == this.storeEmailAddress) {
        this.verified = 'Uw e-mailadres is gecontroleerd.';
      } else {
        this.verified = false;
      }
    }
  },

  created () {
    this.$store.dispatch('WaitingListModule/hasContactInformation');
  },

  mounted () {
    this.yearOfBirth = this.storeYearOfBirth;
    this.emailAddress = this.storeEmailAddress;
    this.verified = this.storeVerifiedEmailAddress;
    this.phonenumber = this.storePhonenumber;

    this.inputEmail();
  },

  methods: {
    isValidEmail (emailAddress) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return regex.test(emailAddress);
    },
    isValidDutchMobilePhonenumber (phonenumber) {
      const regex = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;

      let newPhonenumber = phonenumber
        .replace(/^\+31/g, '0')
        .replace(/^0031/g, '0');

      return regex.test(newPhonenumber) && newPhonenumber.substring(0, 2) == '06';
    },

    inputYearOfBirth () {
      this.yearOfBirthError = false;
    },
    inputEmail () {
      this.emailError = false;
      this.isValidEmailAddress = this.isValidEmail(this.emailAddress);
    },
    inputPhonenumber () {
      this.phonenumberError = false;
    },

    displayVerificationForm () {
      if (!this.emailAddress || this.emailAddress.length === 0 || !this.isValidEmail(this.emailAddress)) {
        this.emailError = 'Vul uw e-mailadres in';
      }

      WaitingListResource
        .requestCode(this.emailAddress, this.phonenumber)
        .then((data) => {
          const actionPayload = {
            emailAddress: this.emailAddress,
            phonenumber: this.phonenumber,
          };

          this.$store.dispatch('WaitingListModule/contactInformation', actionPayload);

          if (data.verified) {
            this.showVerificationForm = false;
            this.emailError = 'U bent al aangemeld voor de wachtrij.';
            this.existingUser = true;
            return;
          }

          this.existingUser = false;
          this.showVerificationForm = this.isValidEmailAddress;
        }).catch((err) => {
          console.log(err)
        })
    },

    submitForm () {
      if (!this.yearOfBirth || this.yearOfBirth == 'null' || this.yearOfBirth.length === 0) {
        this.yearOfBirthError = 'Vul uw geboortejaar in';
      } else {
        this.yearOfBirthError = false;
      }

      if (!this.emailAddress || this.emailAddress.length === 0 || !this.isValidEmail(this.emailAddress)) {
        this.emailError = 'Vul uw e-mailadres in';
      } else if (!this.verified) {
        this.emailError = 'Vul uw e-mailadres in en druk op de ‘verstuur code’.';
      } else {
        this.emailError = false;
      }

      if (this.phonenumber && this.phonenumber.length && (!this.phonenumber || !this.isValidDutchMobilePhonenumber(this.phonenumber))) {
        this.phonenumberError = 'Uw nummer is niet geldig';
      } else {
        this.phonenumberError = false;
      }

      if (this.yearOfBirthError || this.emailError || this.phonenumberError || !this.verified) {
        return;
      }

      const actionPayload = {
        yearOfBirth: this.yearOfBirth,
        emailAddress: this.emailAddress,
        phonenumber: this.phonenumber,
        verifiedEmailAddress: this.verified,
      };

      this.$store.dispatch('WaitingListModule/contactInformation', actionPayload);

      this.$router.push('/wachtrij/locaties');
    },

    isVerified (value) {
      this.emailError = false;

      if (value) {
        const actionPayload = {
          emailAddress: this.emailAddress,
          phonenumber: this.phonenumber,
        };
        this.$store.dispatch('WaitingListModule/contactInformation', actionPayload);
        this.verified = 'Uw e-mailadres is gecontroleerd.';
        setTimeout(() => {
          this.showVerificationForm = false;
        }, 1000);
      } else {
        this.verified = false;
      }
    }
  },
};
</script>

<style scoped>
.form-control {
  margin-bottom: 40px;
}
.form-control:after {
  content: "";
  display: block;
  clear: both;
}
.form-control label {
  display: block;
  margin: 0 0 10px;
  font-weight: bold;
}
#year-of-birth-select {
  width: 120px;
}
#verify-email-address-button {
  width: 100%;
  margin-top: 15px;
}
#phonenumber {
  margin-bottom: 10px;
}
#send-phone-number-button {
  line-height: 28px;
}
@media screen and (min-width: 650px) {
  #verify-email-address-button {
    width: auto;
  }
  #phonenumber {
    max-width: 200px;
    margin: 0 10px 0 0;
  }
}
</style>
