import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state () {
    return {
      enabled: true,
      show: false,
      faq: [{
        subject: '',
        list: [{
          question: 'Ik wil niet gevaccineerd worden. Mag ik mijn afspraak aan iemand anders geven?',
          answer: 'De uitnodiging en de code zijn alleen voor u bedoeld. U mag de code niet aan iemand anders geven.',
        }, {
          question: 'Hoe vaak kan ik mijn afspraak wijzigen?',
          answer: 'U kunt uw afspraak 1x wijzigen met de code die in uw brief staat. Daarna is de code niet meer geldig en kunt u uw afpraak niet meer wijzigen.',
        }, {
          question: 'Ik lees dat er in de boostercampagne met 2 verschillende vaccins geprikt wordt. Kan ik zelf kiezen waar ik gevaccineerd wordt? ',
          answer: 'Er is geen keuzemogelijkheid. De vaccinatie wordt gezet met een mRNA vaccin: Pfizer/BioNTech of Moderna. Het maakt niet uit welk vaccin u als 1e en 2e prik heeft gehad.',
        }, {
          question: 'Ik heb geen uitnodiging ontvangen, hoe kan ik mij aanmelden?',
          answer: 'U kunt uw uitnodging verkrijgen via uw werkgever.',
        }, {
          question: 'Ik ben mijn uitnodiging en dus mijn unieke code kwijt, hoe kan ik nu een afspraak boeken?',
          answer: 'U kunt uw werkgever benaderen voor uw code.',
        }, {
          question: 'Wat gebeurt er met mijn vaccinatiegegevens nadat ik geprikt ben?',
          answer: 'U bepaalt zelf of u toestemming geeft dat uw gegevens aan het RIVM doorgegeven worden. Het RIVM houdt deze gegevens bij zodat zij weten hoeveel mensen in Nederland gevaccineerd zijn. Ook kunnen zij u bereiken als er bijzondere bijwerkingen bekend zijn van het vaccin dat u gekregen hebt. Als er geen toestemming gegeven wordt, kunnen de gegevens niet worden gebruikt voor verwerking in de CoronaCheck-app.',
        }, {
          question: 'Mag ik iemand meenemen naar de afspraak?',
          answer: 'Het wordt erg druk. Kom daarom het liefst alleen. Als dat niet anders kan, mag u iemand meenemen.',
        }, {
          question: 'Ik ben eerder positief getest op corona, moet ik dan ook een boostervaccinatie halen?',
          answer: 'Ja. Tussen doorgemaakte COVID en de boostervaccinatie moet 6 maanden zitten.',
        }, {
          question: 'Ik heb mijn afspraak gemist, kan ik een nieuwe afspraak maken?',
          answer: 'Uw code kunt u maar 1x gebruiken voor een afspraak. U kunt uw werkgever benaderen voor het aanvragen van een nieuwe code.',
        }, {
          question: 'Ik heb al een vaccinatiepaspoort. Moet ik dat meenemen naar de afspraak?',
          answer: 'U mag het meenemen, maar het gele vaccinatieboekje is geen officieel vaccinatiepaspoort.',
        }, {
          question: 'Krijg ik een bewijs van vaccinatie bij het prikken?',
          answer: 'U krijgt een vaccinatieregistratie mee als u bent gevaccineerd.',
        }, {
          question: 'Waar moet ik precies zijn?',
          answer: 'In de bevestiging die u ontvangt nadat u een afspraak hebt gemaakt, staat het adres waar u moet zijn voor de vaccinatie. U kiest zelf op welke priklocatie u gevaccineerd wilt worden.',
        }, {
          question: 'Hoelang duurt de afspraak?',
          answer: 'Het vaccineren zelf gaat snel. Daarna moet u nog 15 minuten blijven zitten. We kijken dan of u last krijgt van een bijwerking.',
        }, {
          question: 'Hoelang van te voren moet ik aanwezig zijn?',
          answer: 'Kom op tijd, maar niet eerder dan vijf minuten voor uw prikafspraak voor een goede doorstroming.',
        }, {
          question: 'Is het vaccin wel veilig met mijn medische achtergrond?',
          answer: 'Bij uw uitnodiging zit een vragenlijst over uw gezondheid. Daarin kunt u lezen wat u moet doen als u bijvoorbeeld een stollingsstoornis of epilepsie hebt. Vul deze vragenlijst thuis in en neem mee naar de afspraak.',
        },]
      }],
    };
  },
  mutations,
  actions,
  getters,
};
