import axios from 'axios';

class Voucher {
  // eslint-disable-next-line class-methods-use-this
  validate (voucherCode) {
    return axios({
      method: 'get',
      url: 'https://api.ziekenhuiscoronaprik.nl/v1/public/vouchers?voucher_code=' + voucherCode,
    })
      .then(function (response) {
        return response.data;
      });
  }
  cancel (url) {
    return axios({
      method: 'POST',
      url
    })
      .then(function (response) {
        return response.data;
      });
  }
}
export { Voucher as default };
