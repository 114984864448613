export default {
  setAnswer (context, payload) {
    context.commit('setAnswer', payload);
    localStorage.setItem('answers', JSON.stringify(context.getters.answers));
  },
  hasAnswers (context) {
    const answers = JSON.parse(localStorage.getItem('answers'));

    if (!answers) {
      return;
    }

    answers.forEach(function(answer) {
      context.commit('setAnswer', answer);
    });
  },
};
