<template>
  <main>
    <section>
      <h1>Controleer uw gegevens</h1>
      <p>Hieronder staan uw gegevens. Controleer of ze juist zijn.</p>
    </section>

    <section>
      <dl>
        <dt>Persoonsgegevens <router-link to="/wachtrij/contactgegevens">Bewerken</router-link>
        </dt>
        <dd>
          <label>Geboortejaar:</label>
          <div class="value">
            {{ yearOfBirth }}
          </div>
        </dd>
        <dd>
          <label>E-mail:</label>
          <div class="value">
            {{ emailAddress }}
          </div>
        </dd>
        <dd>
          <label>Telefoonnummer:</label>
          <div class="value">
            {{ phonenumber }}
          </div>
        </dd>
      </dl>

      <dl id="locations">
        <dt>Postcode en voorkeurslocaties <router-link to="/wachtrij/locaties">Bewerken</router-link>
        </dt>
        <dd>
          <label>Postcode:</label>
          <div class="value">
            {{ zipcode }}
          </div>
        </dd>
        <dd>
          <label>Voorkeurslocaties:</label>
          <div class="value">
            <div
              class="location"
              v-for="(location, index) in locationsData"
              :key="location.id"
            >
              <strong>{{ index + 1 }}. {{ location.name }}</strong>
              <br>
              {{ location.address }}
              <br>
              {{ location.postal_code }} {{ location.locality }}
            </div>
          </div>
        </dd>
      </dl>
    </section>

    <section class="navigation-buttons">
      <router-button
        to="/wachtrij/locaties"
        class="secondary"
      ><span class="icon arrow-head-left"></span> Vorige</router-button>
      <base-button
        type="button"
        @click.native.prevent="registerUser"
      >Aanmelden <span class="icon arrow-head-right"></span></base-button>
    </section>
  </main>
</template>

<script>
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';
import BaseButton from '../../SharedKernel/Atoms/Buttons/BaseButton.vue';

import WaitingList from '@/Infrastracture/WaitingList';
const WaitingListResource = new WaitingList();

export default {
  components: {
    RouterButton,
    BaseButton,
  },
  computed: {
    yearOfBirth () {
      return this.$store.getters['WaitingListModule/yearOfBirth'];
    },
    emailAddress () {
      return this.$store.getters['WaitingListModule/emailAddress'];
    },
    phonenumber () {
      return this.$store.getters['WaitingListModule/phonenumber'];
    },
    zipcode () {
      return this.$store.getters['WaitingListModule/zipcode'];
    },
    preferredLocations () {
      return this.$store.getters['WaitingListModule/preferredLocations'];
    },
    locationsData () {
      return this.$store.getters['WaitingListModule/locationsData'];
    },
    finalizationUrl () {
      return this.$store.getters['WaitingListModule/finalizationUrl'];
    },
  },

  created () {
    this.$store.dispatch('WaitingListModule/hasContactInformation');
    this.$store.dispatch('WaitingListModule/hasPreferredLocations');
  },

  methods: {
    registerUser () {
      WaitingListResource
        .register(this.finalizationUrl, this.zipcode, this.preferredLocations, this.yearOfBirth, this.phonenumber)
        .then((data) => {
          if (data.success) {
            this.$store.dispatch('WaitingListModule/signedUp', true);
            this.$router.push('/wachtrij/aangemeld');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
dl {
  margin: 0 0 40px;
}
dt {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
  font-weight: bold;
}
dt a {
  font-weight: normal;
}
dd {
  padding: 5px 10px;
  border-width: 0 1px;
  border-style: solid;
  border-color: #000;
}
dd:last-child {
  padding-bottom: 10px;
  border-bottom-width: 1px;
}
dt + dd {
  padding-top: 10px;
  border-top-width: 1px;
}
.location {
  margin: 0 0 20px;
}
.location:last-child {
  margin: 0;
}
@media screen and (min-width: 650px) {
  dd {
    display: table;
    width: 100%;
    padding: 0 10px;
  }
  dd > * {
    display: table-cell;
  }
  dd label {
    width: 180px;
  }
}
</style>
