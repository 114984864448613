import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state () {
    return {
      completed: false,
      questionnaire: [
        {
          id: 'positiveWithin4Weeks',
          question: 'Bent u na uw laatste vaccinatie door de GGD positief getest op het coronavirus?',
          description: '',
          options: {
            yes: {
              nl: 'Ja',
              en: 'Yes',
            },
            no: {
              nl: 'Nee',
              en: 'No',
            },
          },
          answer: null,
          deniedOption: 'yes',
        },
        {
          id: 'currentSymptoms',
          question: 'Heeft u op dit moment klachten die bij corona passen?',
          description: '<p>Bijvoorbeeld hoesten, koorts, niezen, verkoudheid of benauwdheid.</p>',
          options: {
            yes: {
              nl: 'Ja',
              en: 'Yes',
            },
            no: {
              nl: 'Nee',
              en: 'No',
            },
          },
          answer: null,
          deniedOption: 'yes',
        },
        {
          id: 'homeQuarantine',
          question: 'Zit u thuis in quarantaine?',
          description: `
<p>Mogelijke redenen zijn:</p>
<ul>
  <li>U hebt contact gehad met iemand met corona.</li>
  <li>U hebt een melding gekregen via de CoronaMelder-app.</li>
  <li>U hebt een coronatest gedaan en wacht op de uitslag.</li>
  <li>U bent in een oranje of rood reisgebied geweest.</li>
</ul>
<p>Is een van deze situaties op u van toepassing?`,
          options: {
            yes: {
              nl: 'Ja',
              en: 'Yes',
            },
            no: {
              nl: 'Nee',
              en: 'No',
            },
          },
          answer: null,
          deniedOption: 'yes',
        },
        {
          id: 'otherVaccination',
          question: 'Krijgt u in de komende twee weken een vaccinatie tegen iets anders dan corona? Of heeft u die afgelopen week gehad?',
          description: '<p>Bijvoorbeeld een vaccinatie voor op reis.</p>',
          options: {
            yes: {
              nl: 'Ja',
              en: 'Yes',
            },
            no: {
              nl: 'Nee',
              en: 'No',
            },
          },
          answer: null,
          deniedOption: 'yes',
        },
        {
          id: 'thrombosisDervice',
          question: 'Bent u onder behandeling van de trombosedienst?',
          description: 'U kunt contact opnemen met uw eigen trombosedienst om te bespreken of de boostervaccinatie door kan gaan of uitgesteld moet worden.',
          options: {
            yes: {
              nl: 'Ja',
              en: 'Yes',
            },
            no: {
              nl: 'Nee',
              en: 'No',
            },
          },
          answer: null,
          deniedOption: 'yes',
        },
        {
          id: 'narcoticOperation',
          question: 'Wordt u de komende week geopereerd?',
          description: '',
          options: {
            yes: {
              nl: 'Ja',
              en: 'Yes',
            },
            no: {
              nl: 'Nee',
              en: 'No',
            },
          },
          answer: null,
          deniedOption: 'yes',
        },
      ],
      continue: false,
    };
  },
  mutations,
  actions,
  getters,
};
