<template>
  <main>
    <form @submit.prevent="submitForm">
      <section>
        <h1>Vul uw vaccinatiecode in</h1>
        <p>In de brief die u heeft gekregen staat een vaccinatiecode. De code bestaat uit 16 cijfers en letters. Typ deze code over in het onderstaande veld.</p>
      </section>

      <section>
        <div class="form-control">
          <label for="vax_code">Vul uw vaccinatiecode in</label>
          <input
            type="text"
            id="vax_code"
            placeholder="1234 ABCD 5678 EFGH"
            autocomplete="off"
            v-model.trim="voucherCode"
            autofocus
            :class="{error: hasError}"
          />
          <alert
            mode="error with-icon"
            icon="error"
            v-if="hasError"
            role="img"
            aria-label="Foutmelding"
          >{{ hasError }}</alert>
        </div>
      </section>

      <section class="navigation-buttons">
        <router-button
          :to="'/gezondheidsvragen/' + previousStep"
          class="secondary"
        ><span class="icon arrow-head-left"></span> Vorige</router-button>
        <base-button>
          Volgende
          <span class="icon arrow-head-right"></span>
        </base-button>
      </section>
    </form>
  </main>
</template>

<script>
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';
import BaseButton from '@/SharedKernel/Atoms/Buttons/BaseButton.vue';
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';
import Voucher from '@/Infrastracture/Voucher';
const VoucherResource = new Voucher();

export default {
  name: 'EntryForm',
  components: {
    RouterButton,
    BaseButton,
    Alert,
  },

  data () {
    return {
      voucherCode: null,
      hasError: false,
      voucherIsValid: null,
    };
  },

  computed: {
    previousStep () {
      return this.$store.getters['healthCheckModule/numberOfQuestions'] - 1;
    }
  },

  methods: {
    async submitForm () {
      this.hasError = '';
      if (!this.voucherCode) {
        this.hasError = 'Vul alstublieft uw vaccinatiecode in';
        return;
      }

      const invalidCodeMessage = 'U heeft de code niet goed ingevuld of de code is al meer dan één keer gebruikt voor een vaccinatieafspraak. Probeer het opnieuw.'

      this.voucherCode = this.voucherCode.replace(/\s/g, '').toUpperCase();
      if (this.voucherCode.length !== 16) {
        this.hasError = invalidCodeMessage;
        return;
      }

      VoucherResource.
        validate(this.voucherCode)
        .then((data) => {
          try {
            if (data.is_valid || data.date) {
              const actionPayload = {
                voucherCode: this.voucherCode,
                hasValidVoucherCode: data.is_valid,
                signedUrls: data.clients_signed_urls,
                hasBooking: !!data.date, // get booking status
                client: data.client ?? [],
                bookingDate: data.date ?? null,
                bookingContent: data.content ?? [],
                bookingCancelUrl: data.cancel_signed_url
              };

              this.$store.dispatch('voucherCodeModule/auth', actionPayload);

              if (actionPayload.hasBooking === true) {
                this.$router.push('/afspraak');
              } else {
                this.$router.push('/locaties');
              }
            } else {
              data.message = invalidCodeMessage;
              this.hasError = data.message;
            }
          } catch (error) {
            console.log(error);
          }
        })
        .catch(error => console.log('error', error));
    },
  },
};
</script>

<style scoped>
.form-control {
  margin-bottom: 20px;
}
.form-control:after {
  content: "";
  display: block;
  clear: both;
}
.form-control label {
  display: block;
  margin-bottom: 10px;
}

#locations-section ul {
  background: #fff;
}
#locations-section li {
  margin-top: -1px;
}
#locations-section a {
  position: relative;
  display: block;
  padding: 10px;
  border: 1px solid #01689a;
}
#locations-section a:hover {
  text-decoration: underline;
}
#locations-section a:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  right: 10px;
  border-width: 8px 0 8px 8px;
  border-style: solid;
  border-color: transparent transparent transparent #000000;
  margin-top: -8px;
}
#locations-section .address {
  font-size: 14px;
  font-weight: 700;
}

#vax_code {
  max-width: 250px;
  text-transform: uppercase;
}
</style>
