export default {
  setYearOfBirth (state, yearOfBirth) {
    state.yearOfBirth = yearOfBirth;
  },
  setEmailAddress (state, emailAddress) {
    state.emailAddress = emailAddress;
  },
  setVerifiedEmailAddress (state, verified) {
    state.verifiedEmailAddress = verified;
  },
  setPhonenumber (state, phonenumber) {
    state.phonenumber = phonenumber;
  },
  setZipcode (state, zipcode) {
    state.zipcode = zipcode;
  },
  setPreferredLocations (state, locations) {
    state.preferredLocations = locations;
  },
  setLocationsData (state, locations) {
    state.locationsData = locations;
  },
  setFinalizationUrl (state, signedUrl) {
    state.finalizationUrl = signedUrl;
  },
  setSignedUp (state, signedUp) {
    state.signedUp = signedUp;
  }
};
