export default {
  yearOfBirth (state) {
    return state.yearOfBirth;
  },
  emailAddress (state) {
    return state.emailAddress;
  },
  verifiedEmailAddress (state) {
    return state.verifiedEmailAddress;
  },
  phonenumber (state) {
    return state.phonenumber;
  },
  zipcode (state) {
    return state.zipcode;
  },
  preferredLocations (state) {
    return state.preferredLocations;
  },
  locationsData (state) {
    return state.locationsData;
  },
  finalizationUrl (state) {
    return state.finalizationUrl;
  },
  signedUp (state) {
    return state.signedUp;
  },
};
