import axios from 'axios';

const baseUrl = 'https://api-vaccine-test.globalticket.com/v1/public/waitingList';

class WaitingList {
  requestCode (emailAddress, phoneNumber) {
    return axios({
      method: 'POST',
      url: baseUrl + '/add/contactDetails?email_address=' + emailAddress +'&phone_number=' + phoneNumber,
    })
      .then(function (response) {
        return response.data;
      });
  }

  verify (verificationCode, emailAddress, phoneNumber) {
    return axios({
      method: 'POST',
      url: baseUrl + '/verify/contactDetails?email_address=' + emailAddress +'&phone_number=' + phoneNumber + '&verification_code=' + verificationCode,
    })
      .then(function (response) {
        return response.data;
      });
  }

  register (signedUrl, zipCode, preferredLocations, birthYear, phoneNumber) {
    return axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: signedUrl.replace('http://', 'https://'),
      data: {
        preferred_locations: preferredLocations,
        zip_code: zipCode,
        birth_year: birthYear,
        phone_number: phoneNumber,
      }
    })
      .then(function (response) {
        return response.data;
      });
  }
}
export { WaitingList as default };
