<template>
  <main>
    <section class="printable">
      <h1>U heeft al een afspraak gemaakt</h1>
      <p v-if="isAuthenticated">Lukt het niet om naar de geplande afspraak te komen? Hieronder kunt u de afspraak veranderen of afzeggen.</p>
      <p v-else>Uw afspraak zal binnen 12 uur plaatsvinden. Hierdoor is het niet meer mogelijk deze afspraak te wijzigen of af te zeggen.</p>
    </section>

    <section class="printable">
      <div id="booking-information">
        <div class="qr-code">
          <qrcode-vue
            :value="voucherCode"
            size="140"
            level="H"
          />
        </div>
        <div class="information">
          <span>Uw afspraak</span>
          <br>
          <br>
          <span>{{ location }}</span>
          <br>
          <span>{{ date }} om {{ time }}</span>
          <br>
          <span>{{ voucherCode }}</span>
        </div>
      </div>
    </section>

    <section>
      <div class="buttons-container">
        <base-button @click.native.prevent="printPage">Print bevestiging</base-button>
        <base-button
          @click.native.prevent="openAmendDialog"
          v-if="isAuthenticated"
        >Wijzig afspraak</base-button>
      </div>
    </section>

    <section v-if="isAuthenticated">
      <p>Kunt u echt niet komen? Dan kunt u deze afspraak <a
          href="#"
          @click.prevent="openCancelDialog"
        >afzeggen</a>.</p>
    </section>

    <section>
      <alert
        mode="error with-icon"
        icon="error"
        v-if="cancelError"
        role="img"
        aria-label="Foutmelding"
      >{{ cancelError }}</alert>
    </section>

    <section class="printable">
      <h1 v-if="contentTitle">{{ contentTitle }}</h1>
      <div
        v-if="contentText"
        v-html="contentText"
      ></div>
    </section>

    <base-dialog
      v-if="isAuthenticated"
      :show="showCancelDialog"
      @close="closeCancelDialog"
    >
      <p>U staat op het punt uw afspraak af te zeggen. Klik op Ja als u uw afspraak definitief wilt afzeggen. Klik op Nee als dit niet de bedoeling is.</p>
      <div class="buttons-container">
        <base-button @click.native.prevent="closeCancelDialog">Nee, ga terug</base-button>
        <base-button
          @click.native.prevent="SubmitCancelAppointment"
          class="secondary"
        >Ja, zeg afspraak af</base-button>
      </div>
    </base-dialog>

    <base-dialog
      v-if="isAuthenticated"
      :show="showAmendDialog"
      @close="closeAmendDialog"
    >
      <p>U staat op het punt uw afspraak te veranderen. Als u hiermee doorgaat, kunt u direct een nieuwe afspraak inplannen. Wanneer u deze nieuwe afspraak heeft gemaakt, zal uw oude afspraak komen te vervallen. Weet u zeker dat u door wilt gaan?</p>
      <div class="buttons-container">
        <base-button @click.native.prevent="closeAmendDialog">Nee, ga terug</base-button>
        <base-button
          @click.native.prevent="submitChangeOrder"
          class="secondary"
        >Ja, verander afspraak</base-button>
      </div>
    </base-dialog>
  </main>
</template>

<script>
import moment from "moment";
import QrcodeVue from 'qrcode.vue'
import BaseButton from '@/SharedKernel/Atoms/Buttons/BaseButton.vue';
import BaseDialog from '@/SharedKernel/Molecules/Dialogs/BaseDialog.vue';

import Voucher from '@/Infrastracture/Voucher';
import Alert from '@/SharedKernel/Atoms/Alerts/Alert';
const VoucherResource = new Voucher();

export default {
  components: {
    Alert,
    QrcodeVue,
    BaseDialog,
    BaseButton,
  },

  data () {
    return {
      locationInformation: null,
      showCancelDialog: false,
      showAmendDialog: false,
      cancelError: null,
      currentDate: new Date(),
    };
  },

  computed: {
    date () {
      return moment(this.bookingDate)
        .locale('NL')
        .format("dddd D MMMM YYYY");
    },
    time () {
      return moment(this.bookingDate)
        .locale('NL')
        .format("HH:mm");
    },
    voucherCode () {
      return this.$store.getters['voucherCodeModule/voucherCode'];
    },
    location () {
      const bookingInformation = this.$store.getters['voucherCodeModule/bookingInformation'];
      return bookingInformation.name + ', ' + bookingInformation.address + ', ' + bookingInformation.postal_code + ', ' + bookingInformation.locality;
    },
    bookingDate () {
      return this.$store.getters['voucherCodeModule/bookingDate'] * 1000;
    },
    bookingContent () {
      return this.$store.getters['voucherCodeModule/bookingContent'];
    },
    contentTitle () {
      return this.bookingContent.STEP_THANK_YOU_SINGLE_TITLE_1;
    },
    contentText () {
      return this.bookingContent.STEP_THANK_YOU_SINGLE_TEXT_1;
    },
    bookingCancelUrl () {
      return this.$store.getters['voucherCodeModule/bookingCancelUrl'];
    },
    isAuthenticated () {
      return this.$store.getters['voucherCodeModule/isAuthenticated'];
    },
  },

  methods: {
    openCancelDialog () {
      this.showCancelDialog = true;
    },
    closeCancelDialog () {
      this.showCancelDialog = false;
    },
    openAmendDialog () {
      this.showAmendDialog = true;
    },
    closeAmendDialog () {
      this.showAmendDialog = false;
    },
    printPage () {
      window.print();
    },
    async SubmitCancelAppointment () {
      const errorMessage = 'Het annuleren van uw afspraak is niet meer mogelijk. Dit is enkel mogelijk tot 12 uur voor aanvang van uw afspraak. Het kan ook zijn dat uw afspraak reeds geannuleerd is.'
      VoucherResource
        .cancel(this.bookingCancelUrl)
        .then((data) => {
          if (data.is_cancelled) {
            this.onCanceledBooking()
          } else {
            this.cancelError = errorMessage
            this.closeCancelDialog()
          }
        })
        .catch(() => {
          this.cancelError = errorMessage
          this.closeCancelDialog()
        });
    },
    onCanceledBooking () {
      this.$router.push('/uw-afspraak-is-geannuleerd');
    },
    submitChangeOrder () {
      this.$store.dispatch('voucherCodeModule/hasAgreedToChangeOrder');
      this.$router.push('/locaties');
    },
  },
};
</script>

<style scoped>
#booking-information {
  border: 1px solid #000;
  background: #fff;
}
#booking-information .qr-code {
  padding: 30px;
  text-align: center;
}
#booking-information .information {
  padding: 0 30px 30px;
  font-weight: bold;
}

.buttons-container .button {
  margin: 0 0 10px;
}
.buttons-container .button:last-child {
  margin: 0;
}

.dialog {
  text-align: center;
}
.dialog .content p {
  font-weight: bold;
}
.dialog .buttons-container {
  margin-top: 40px;
}
.dialog .button {
  width: 100%;
  margin: 0 0 20px;
  font-size: 14px;
}
.dialog .button:last-child {
  margin: 0;
}

@media screen and (min-width: 650px) {
  #booking-information {
    display: flex;
  }
  #booking-information .information {
    padding: 30px 30px 30px 0;
    font-weight: bold;
  }

  .buttons-container .button {
    margin: 0 20px 0 0;
  }

  .dialog .buttons-container {
    display: flex;
    justify-content: space-between;
  }
  .dialog .button {
    width: 250px;
    margin: 0;
  }
}
</style>
