<template>
  <main>
    <section>
      <h1>Uw afspraak is afgezegd</h1>
      <p>We hebben de afzegging van uw afspraak verwerkt. U kunt een nieuwe afspraak maken door op onderstaande knop te drukken.</p>
    </section>

    <section>
      <router-button to="/locaties">Nieuwe afspraak maken</router-button>
    </section>
  </main>
</template>

<script>
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';

export default {
  components: {
    RouterButton
  },
  mounted() {
    this.$store.commit('voucherCodeModule/resetBookingInformationState');
  }
};
</script>
