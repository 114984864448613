export default {
  setAnswer (state, payload) {
    const id = payload.id;
    const answer = payload.answer;
    let noAnswer = 0;
    let denied = false;

    state.questionnaire.forEach(function(data) {
      if (data.id === id) {
        data.answer = answer;
      }

      if (!data.answer) {
        noAnswer++;
      }

      if (data.deniedOption === answer) {
        denied = true;
      }
    });

    state.completed = !noAnswer;
    state.continue = !denied;
  },
};
