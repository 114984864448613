import Vue from 'vue';
import VueRouter from 'vue-router';

import Entry from './Pages/Entry.vue';
import Questionnaire from './Pages/HealthChecks/Questionnaire.vue';
import EnterCode from './Pages/VoucherCode/EnterCode.vue';
import Locations from './Pages/VoucherCode/Locations.vue';
import Denied from './Pages/HealthChecks/Denied.vue';
import BookingInformation from './Pages/Booking/BookingInformation.vue';
import Cancelled from './Pages/Booking/Cancelled.vue';
import WaitingList from './Pages/WaitingList/WaitingList.vue';
import WaitingListIntroduction from './Pages/WaitingList/Introduction.vue';
import WaitingListContact from './Pages/WaitingList/Contact.vue';
import WaitingListLocations from './Pages/WaitingList/Locations.vue';
import WaitingListSummary from './Pages/WaitingList/Summary.vue';
import WaitingListSignedUp from './Pages/WaitingList/SignedUp.vue';
import NotFound from './Pages/Errors/NotFound';

import store from './Store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Entry,
    },
    {
      path: '/gezondheidsvragen/:id',
      component: Questionnaire,
    },
    {
      path: '/vaccinatiecode',
      component: EnterCode,
      async beforeEnter (to, from, next) {
        try {
          await store.dispatch('healthCheckModule/hasAnswers');

          const completed = store.getters["healthCheckModule/completed"];

          if (completed) {
            next()
          } else {
            next('/')
          }
        } catch (e) {
          next('/')
        }
      }
    },
    {
      path: '/locaties',
      component: Locations,
      async beforeEnter (to, from, next) {
        try {
          await store.dispatch('voucherCodeModule/hasValidVoucherCode');
          await store.dispatch('voucherCodeModule/hasBooking');
          await store.dispatch('voucherCodeModule/hasAgreedToChangeOrder');

          const completed = store.getters["healthCheckModule/completed"];
          const voucherCode = store.getters["voucherCodeModule/voucherCode"];
          const isAuthenticated = store.getters["voucherCodeModule/isAuthenticated"];
          const hasBooking = store.getters['voucherCodeModule/hasBooking'];
          const hasAgreedToChangeOrder = store.getters['voucherCodeModule/hasAgreedToChangeOrder'];

          if (completed && isAuthenticated && (!hasBooking || hasAgreedToChangeOrder)) {
            next()
          } else if (completed && (isAuthenticated || hasBooking) && voucherCode) {
            next('/afspraak')
          } else if (completed) {
            next('/vaccinatiecode')
          } else {
            next('/')
          }
        } catch (e) {
          next('/')
        }
      }
    },
    {
      path: '/u-kunt-nu-geen-afspraak-maken',
      component: Denied,
    },
    {
      path: '/afspraak',
      component: BookingInformation,
      async beforeEnter (to, from, next) {
        try {
          await store.dispatch('voucherCodeModule/hasBooking');

          const completed = store.getters["healthCheckModule/completed"];
          const voucherCode = store.getters["voucherCodeModule/voucherCode"];
          const isAuthenticated = store.getters["voucherCodeModule/isAuthenticated"];
          const hasBooking = store.getters['voucherCodeModule/hasBooking'];
          const hasAgreedToChangeOrder = store.getters['voucherCodeModule/hasAgreedToChangeOrder'];

          if (completed && (isAuthenticated || hasBooking) && voucherCode) {
            next()
          } else if (completed && isAuthenticated && (!hasBooking || hasAgreedToChangeOrder)) {
            next('/locaties')
          } else if (completed) {
            next('/vaccinatiecode')
          } else {
            next('/')
          }
        } catch (e) {
          next('/')
        }
      }
    },
    {
      path: '/uw-afspraak-is-geannuleerd',
      component: Cancelled,
      async beforeEnter (to, from, next) {
        try {
          await store.dispatch('voucherCodeModule/hasValidVoucherCode');

          const completed = store.getters["healthCheckModule/completed"];
          const isAuthenticated = store.getters["voucherCodeModule/isAuthenticated"];
          const hasBooking = store.getters['voucherCodeModule/hasBooking'];

          if (completed && isAuthenticated && hasBooking) {
            next()
          } else if (completed && isAuthenticated && !hasBooking) {
            next('/locaties')
          } else if (completed && !isAuthenticated) {
            next('/vaccinatiecode')
          } else {
            next('/')
          }
        } catch (e) {
          next('/')
        }
      }
    },
    {
      path: '/wachtrij',
      component: WaitingList,
      children: [
        {
          path: '/',
          component: WaitingListIntroduction,
        },
        {
          path: 'contactgegevens',
          component: WaitingListContact,
        },
        {
          path: 'locaties',
          component: WaitingListLocations,
          beforeEnter (to, from, next) {
            try {
              const emailAddress = store.getters["WaitingListModule/emailAddress"];
              const verifiedEmailAddress = store.getters["WaitingListModule/verifiedEmailAddress"];

              if (emailAddress && verifiedEmailAddress) {
                next()
              } else {
                next('/wachtrij')
              }
            } catch (e) {
              next('/')
            }
          }
        },
        {
          path: 'overzicht',
          component: WaitingListSummary,
          beforeEnter (to, from, next) {
            try {
              const emailAddress = store.getters["WaitingListModule/emailAddress"];
              const verifiedEmailAddress = store.getters["WaitingListModule/verifiedEmailAddress"];
              const zipcode = store.getters["WaitingListModule/zipcode"];
              const preferredLocations = store.getters["WaitingListModule/preferredLocations"];

              if (emailAddress && verifiedEmailAddress && zipcode && preferredLocations) {
                next()
              } else if (emailAddress && verifiedEmailAddress) {
                next('/locaties')
              } else {
                next('/wachtrij')
              }
            } catch (e) {
              next('/')
            }
          }
        },
        {
          path: 'aangemeld',
          component: WaitingListSignedUp,
          beforeEnter (to, from, next) {
            try {
              const signedUp = store.getters["WaitingListModule/signedUp"];
              const emailAddress = store.getters["WaitingListModule/emailAddress"];
              const verifiedEmailAddress = store.getters["WaitingListModule/verifiedEmailAddress"];

              if (signedUp) {
                next()
              } else if (emailAddress && verifiedEmailAddress) {
                next('/locaties')
              } else {
                next('/wachtrij')
              }
            } catch (e) {
              next('/')
            }
          }
        }
      ]
    },
    {
      path: '/:notFound(.*)',
      component: NotFound
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.path === from.path) {
      return savedPosition;
    } else if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.afterEach(function (to) {
  store.dispatch('FaqDialogModule/show', (to.hash === '#faq'));
});

export default router;
