<template>
  <main>
    <form @submit.prevent="submitForm">
      <section>
        <div class="questions-and-answers">
          <h1>{{ questionnaire.question }}</h1>
          <div v-html="questionnaire.description"></div>
          <ul>
            <li
              v-for="(option, key, index) in questionnaire.options"
              :key="index"
            >
              <input
                type="radio"
                :id="questionnaire.id + '-' + index"
                :name="questionnaire.id"
                :value="key"
                :checked="key === questionnaire.answer"
                @change="setAnswer(questionnaire.id, key)"
              >
              <label :for="questionnaire.id + '-' + index">
                <radiomark></radiomark>
                {{ option[$i18n.locale] }}
              </label>
            </li>
          </ul>

          <div class="progress-bar">
            <div class="text">Voortgang: {{ currentStep }}/{{ numberOfQuestions }}</div>
            <div class="bar">
              <div
                class="progress"
                :style="{width: progressNumber + '%'}"
                role="progressbar"
                :aria-valuenow="progressNumber"
                aria-valuemin="0"
                aria-valuemax="100"
                :aria-label="'Voortgang: ' + progressNumber + '%'"
              ></div>
            </div>
          </div>
        </div>
      </section>

      <section class="navigation-buttons">
        <router-button
          :to="previousStep"
          class="secondary"
        ><span class="icon arrow-head-left"></span> Vorige</router-button>
        <base-button :disabled="!completed">Volgende <span class="icon arrow-head-right"></span></base-button>
      </section>
    </form>
  </main>
</template>

<script>
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';
import BaseButton from '@/SharedKernel/Atoms/Buttons/BaseButton.vue';
import Radiomark from '@/SharedKernel/Atoms/Icons/Inputs/Radiomark.vue';

export default {
  components: { RouterButton, BaseButton, Radiomark, },
  computed: {
    questionnaireCompleted () {
      return this.$store.state.healthCheckModule.completed;
    },
    questionnaire () {
      const questionnaire = this.$store.state.healthCheckModule.questionnaire;
      return questionnaire[this.$route.params.id];
    },
    completed () {
      return this.questionnaire.answer !== null;
    },
    continue () {
      return this.$store.state.healthCheckModule.continue;
    },
    numberOfQuestions () {
      return this.$store.getters['healthCheckModule/numberOfQuestions'];
    },
    currentStep() {
      return parseInt(this.$route.params.id) + 1;
    },
    progressNumber () {
      const id = parseInt(this.$route.params.id) + 1;

      return Math.round(100 / this.numberOfQuestions * id);
    },
    previousStep () {
      const id = parseInt(this.$route.params.id);

      if (id) {
        return (parseInt(id) - 1).toString();
      }

      return '/';
    },
    nextStep () {
      const id = parseInt(this.$route.params.id);

      if (!this.continue) {
        return '/u-kunt-nu-geen-afspraak-maken';
      } else if (id < (this.numberOfQuestions - 1) && this.completed) {
        return (parseInt(id) + 1).toString();
      }
      return '/vaccinatiecode';
    }
  },
  methods: {
    setAnswer (id, answer) {
      const actionPayload = {
        id: id,
        answer: answer,
      };

      this.$store.dispatch('healthCheckModule/setAnswer', actionPayload);
    },
    submitForm () {
      this.$router.push(this.nextStep);
    }
  }
};
</script>

<style scoped>
.questions-and-answers {
}
.questions-and-answers section {
  padding: 20px;
  margin: 0 0 20px;
  background: #fff;
}
.questions-and-answers ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.questions-and-answers li {
  margin: 0 0 8px;
}
.questions-and-answers input {
  position: absolute;
  opacity: 0;
}
.questions-and-answers label {
  position: relative;
  display: block;
  padding-left: 35px;
}
.questions-and-answers input:focus ~ label {
  outline: 2px dotted #000;
  z-index: 1010;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 2px #fff;
  box-shadow: 0 0 0 2px #fff;
}
.questions-and-answers .radiomark {
  position: absolute;
  top: 3px;
  left: 0;
}

.progress-bar {
  text-align: center;
}
.progress-bar .text {
  padding: 10px;
}
.progress-bar .bar {
  height: 10px;
  background: #ccc;
}
.progress-bar .progress {
  height: 100%;
  width: 0%;
  background: #01689a;
  transition: all 0.8s ease;
}
</style>
