<template>
  <main>
    <section>
      <h1>Aanmelden voor reservelijst</h1>
      <p>Via deze website kunt u zich aanmelden voor de reservelijst coronavaccinatie. Als er op een priklocatie vaccinaties beschikbaar zijn, krijgt u een uitnodiging.</p>
      <h2>Hoe werkt de reservelijst?</h2>
      <ul>
        <li>U meldt zich aan via deze pagina</li>
        <li>U krijgt bericht als er vaccinaties beschikbaar zijn.</li>
        <li>U maakt online een afspraak met de unieke code die u krijgt</li>
      </ul>
      <p>Wilt u weten wat we met uw gegevens doen? Lees onze <a href="https://www.rivm.nl/documenten/privacyverklaring-rivm" target="_blank" rel="noopener noreferrer">privacyverklaring</a>.</p>
    </section>

    <section>
      <alert
        mode="with-icon"
        icon="warning"
        role="img"
        aria-label="Waarschuwing"
      >
        Heeft u klachten die op corona lijken zoals verkoudheid, koorts of benauwdheid? Wordt u binnenkort geopereerd? Dan kunt u nu geen afspraak maken.
      </alert>
    </section>

    <section class="navigation-buttons">
      <router-button to="/wachtrij/contactgegevens">Starten</router-button>
    </section>
  </main>
</template>

<script>
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';

export default {
  components: { Alert, RouterButton },
};
</script>
