export default {
  answers (state) {
    const answers = [];
    const questionnaire = state.questionnaire;

    questionnaire.forEach(function (data) {
      answers.push({
        id: data.id,
        answer: data.answer,
      });
    });

    return answers;
  },
  numberOfQuestions (state) {
    return state.questionnaire.length;
  },
  continue (state) {
    return state.continue;
  },
  completed (state) {
    return state.completed;
  }
};
