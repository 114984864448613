<template>
  <main>
    <form @submit.prevent="submitForm">
      <section>
        <h1>Kies uw voorkeurslocaties</h1>
        <p>Kies 3 locaties uit waar u naartoe kunt reizen voor een coronavaccinatie.</p>
      </section>

      <section>
        <div class="form-control">
          <label for="zipcode">Vul de vier cijfers van je postcode in</label>
          <input
            type="text"
            id="zipcode"
            maxlength="4"
            placeholder="1234"
            v-model="zipcode"
            @input="zipcodeFormat"
            autocomplete="off"
            pattern="[0-9]*"
            inputmode="numeric"
            autofocus
          />
        </div>

        <div
          class="loading"
          v-if="isLoading"
        >
          <ring-spinner></ring-spinner>
        </div>
        <div
          class="form-control"
          v-else-if="locations.length"
        >
          <label>Kies minimaal één locatie</label>
          <ul id="locations">
            <li
              v-for="location in locations"
              :key="location.id"
            >
              <input
                type="checkbox"
                name="location"
                :id="'location-' + location.id"
                :value="location.url"
                v-model="selectedLocations"
                :disabled="selectedLocations.length >= 3 && selectedLocations.indexOf(location.id) === -1"
              >
              <label :for="'location-' + location.id">
                <span class="indicator"></span>
                <span class="location">{{ location.name }}</span> {{ location.address }}, {{ location.postal_code }}, {{ location.locality }}
                <br>
              </label>
            </li>
          </ul>
          <alert
            mode="error with-icon"
            icon="error"
            role="img"
            aria-label="Foutmelding"
            v-if="locationError"
          >Kies een locatie in de buurt.</alert>
        </div>
      </section>

      <section
        id="selected-locations-preview"
        v-if="selectedLocations && selectedLocations.length"
      >
        <dl>
          <dt><span>Voorkeurslocaties</span> <span class="max-locations">Geselecteerd {{ selectedLocations.length }} van {{ maxLocations }}</span></dt>
          <dd
            v-for="(location, index) in selectedLocationsData"
            :key="location.id"
          >
            <strong>{{ index + 1 }}. {{ location.name }}</strong>
            <br>
            {{ location.address }}
            <br>
            {{ location.postal_code }} {{ location.locality }}
          </dd>
        </dl>
      </section>

      <section class="navigation-buttons">
        <router-button
          to="/wachtrij/contactgegevens"
          class="secondary"
        ><span class="icon arrow-head-left"></span> Vorige</router-button>
        <base-button :disabled="selectedLocations.length === 0">
          Volgende
          <span class="icon arrow-head-right"></span>
        </base-button>
      </section>
    </form>
  </main>
</template>

<script>
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';
import BaseButton from '@/SharedKernel/Atoms/Buttons/BaseButton.vue';
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';
import RingSpinner from '@/SharedKernel/Atoms/Spinners/RingSpinner.vue';

import Client from '@/Infrastracture/Client';
const ClientResource = new Client();

export default {
  components: {
    RouterButton,
    BaseButton,
    Alert,
    RingSpinner,
  },

  data () {
    return {
      zipcode: null,
      radius: 50,
      isLoading: false,
      selectedLocations: [],
      locations: [],
      maxLocations: 3,
      validateZipcode: false,
      locationError: false,
    };
  },

  computed: {
    storeZipcode () {
      return this.$store.getters['WaitingListModule/zipcode'];
    },
    storeSelectedLocations () {
      return this.$store.getters['WaitingListModule/preferredLocations'];
    },
    years () {
      return this.currentYear - this.minimumYear;
    },
    formIsValid () {
      return false;
    },
    selectedLocationsData () {
      const selectedLocations = this.selectedLocations;
      const locations = [];

      this.locations.forEach((location) => {
        selectedLocations.forEach((data) => {
          if (location.id == data) {
            locations.push(location);
          }
        });
      });

      return locations;
    }
  },

  created () {
    this.$store.dispatch('WaitingListModule/hasPreferredLocations');
  },

  mounted () {
    this.zipcode = this.storeZipcode;
    this.zipcodeFormat();
    this.selectedLocations = this.storeSelectedLocations;
  },

  methods: {
    zipcodeFormat () {
      if (!this.zipcode) {
        return;
      }
      this.zipcode = this.zipcode.substring(0, 4);
      this.zipcode = this.zipcode.replace(/[^0-9]+/g, '');

      if (this.zipcode.length === 4) {
        this.getLocations();
      }
    },
    getLocations () {
      this.isLoading = true
      this.locations = [];

      ClientResource
        .listAll()
        .then((data) => {
          const locations = data;

          locations.forEach((location) => {
            location['url'] = location.id;
            this.locations.push(location);
          });

          this.locations = locations;

          ClientResource
            .list(this.zipcode, this.radius)
            .then((data) => {
              data.forEach((location) => {
                locations.forEach((loc, index) => {
                  if (location.id === loc.id) {
                    locations.splice(index, 1);
                    locations.unshift(loc);
                  }
                });
              });
            })
            .then(() => {
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              console.log('error', error)
            });
        })
        .catch((error) => {
          this.isLoading = false
          console.log('error', error);
        });
    },
    submitForm () {
      if (this.selectedLocations.length === 0 || this.selectedLocations.length > 3) {
        return;
      }

      const actionPayload = {
        zipcode: this.zipcode,
        locations: this.selectedLocations,
        locationsData: this.selectedLocationsData,
      };

      this.$store.dispatch('WaitingListModule/preferredLocations', actionPayload);

      this.$router.push('/wachtrij/overzicht');
    }
  },
};
</script>

<style scoped>
.form-control {
  margin-bottom: 20px;
}
.form-control:after {
  content: "";
  display: block;
  clear: both;
}
.form-control label {
  display: block;
  margin-bottom: 10px;
}
#zipcode {
  width: 120px;
}

#locations {
  overflow: auto;
  max-height: 275px;
  padding: 2px;
  margin: 0;
  list-style: none;
}
#locations li {
  position: relative;
  margin-bottom: 10px;
}
#locations input {
  position: absolute;
  opacity: 0;
  z-index: -9999;
}
#locations label {
  position: relative;
  display: block;
  padding: 10px 10px 10px 50px;
  border: 1px solid #01689a;
  background: #fff;
}
#locations label .indicator {
  position: absolute;
  top: 50%;
  left: 15px;
  height: 20px;
  width: 20px;
  border: 2px solid #000000;
  margin-top: -10px;
}
#locations input:focus ~ label {
  position: relative;
  outline: 2px dotted #000;
  z-index: 1010;
  outline-offset: -1px;
  -webkit-box-shadow: 0 0 0 2px #fff;
  box-shadow: 0 0 0 2px #fff;
}
#locations input:disabled ~ label {
  opacity: 0.5;
}
#locations :checked ~ label .indicator:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 12px;
  width: 12px;
  margin: auto;
  background: #01689a;
}
#locations label .location {
  font-weight: bold;
}

dl {
  margin: 0 0 20px;
}
dt {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
  font-weight: bold;
}
dd {
  padding: 10px;
  border-width: 0 1px;
  border-style: solid;
  border-color: #000;
}
dd:last-child {
  padding-bottom: 10px;
  border-bottom-width: 1px;
}
dt + dd {
  padding-top: 10px;
  border-top-width: 1px;
}
</style>
