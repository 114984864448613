<template>
  <div>
    <the-header></the-header>
    <div class="wrapper">
      <maintenance v-if="maintenanceIsActive"></maintenance>
      <router-view v-else></router-view>
    </div>
    <the-footer></the-footer>
  </div>
</template>
<script>

import TheHeader from '@/SharedKernel/Templates/TheHeader.vue';
import TheFooter from '@/SharedKernel/Templates/TheFooter.vue';
import Maintenance from './Pages/Maintenance/Maintenance.vue';
import Session from '@/Infrastracture/Session';
const LocalSession = new Session();
export default {
  components: {
    TheFooter,
    TheHeader,
    Maintenance,
  },
  computed: {
    maintenanceIsActive () {
      return this.$store.getters["maintenanceModule/isActive"];
    }
  },
  created () {
    if (LocalSession.hasInvalidSession()) {
      LocalSession.clear()
      LocalSession.setClientSession()
    }

    this.$store.dispatch('healthCheckModule/hasAnswers');
    this.$store.dispatch('voucherCodeModule/hasValidVoucherCode');
    this.$store.dispatch('voucherCodeModule/hasBooking');
  },
};
</script>

<style type="text/css">
@import "./assets/css/fonts.css";
@import "./assets/css/gt.css";
@import "./assets/css/main.css";
</style>

<style scoped>
.wrapper {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  overflow: hidden;
  padding: 60px 20px;
  position: relative;
}
</style>
