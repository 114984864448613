export default {
  contactInformation (context, payload) {
    context.commit('setYearOfBirth', payload.yearOfBirth);
    context.commit('setEmailAddress', payload.emailAddress);
    context.commit('setVerifiedEmailAddress', payload.verifiedEmailAddress);
    context.commit('setPhonenumber', payload.phonenumber);

    localStorage.setItem('contactInformation', JSON.stringify(payload));
  },
  preferredLocations (context, payload) {
    context.commit('setZipcode', payload.zipcode);
    context.commit('setPreferredLocations', payload.locations);
    context.commit('setLocationsData', payload.locationsData);

    localStorage.setItem('preferredLocations', JSON.stringify(payload));
  },

  hasContactInformation (context) {
    const contactInformation = JSON.parse(localStorage.getItem('contactInformation'));

    if (!contactInformation) {
      return;
    }

    context.commit('setYearOfBirth', contactInformation.yearOfBirth);
    context.commit('setEmailAddress', contactInformation.emailAddress);
    context.commit('setVerifiedEmailAddress', contactInformation.verifiedEmailAddress);
    context.commit('setPhonenumber', contactInformation.phonenumber);
  },

  hasPreferredLocations (context) {
    const preferredLocations = JSON.parse(localStorage.getItem('preferredLocations'));

    if (!preferredLocations) {
      return;
    }

    context.commit('setZipcode', preferredLocations.zipcode);
    context.commit('setPreferredLocations', preferredLocations.locations);
    context.commit('setLocationsData', preferredLocations.locationsData);
  },

  resetData (context) {
    context.commit('setYearOfBirth', null);
    context.commit('setEmailAddress', null);
    context.commit('setVerifiedEmailAddress', false);
    context.commit('setPhonenumber', null);
    context.commit('setZipcode',null );
    context.commit('setPreferredLocations', []);
    context.commit('setLocationsData', []);
    context.commit('setFinalizationUrl', '');
  },

  setFinalizationUrl (context, payload) {
    context.commit('setFinalizationUrl', payload.signedUrl);
  },

  signedUp (context, signedUp) {
    context.commit('setSignedUp', signedUp);
  },
};
