<template>
  <main>
    <section>
      <h1>Kies een locatie</h1>
      <p>Vul hieronder de vier cijfers van uw postcode in. Vul ook in hoever u maximaal kunt reizen. Kies daarna een van de locaties bij u in de buurt.</p>
    </section>

    <form @submit.prevent="getLocations">
      <section>
        <div class="form-control">
          <label for="zip_code">Vul de vier cijfers van uw postcode in</label>
          <input
            type="text"
            id="zip_code"
            maxlength="4"
            placeholder="1234"
            v-model="zipcode"
            @input="zipcodeFormat"
            autocomplete="off"
            pattern="[0-9]*"
            inputmode="numeric"
            autofocus
            :class="{ error: zipcodeError }"
          />
          <alert
            mode="error with-icon"
            icon="error"
            v-if="zipcodeError"
            role="img"
            aria-label="Foutmelding"
          >{{ zipcodeError }}</alert>
        </div>
        <div class="form-control">
          <label for="radius">Hoe ver wilt u reizen?</label>
          <div class="selectbox">
            <select
              id="radius"
              v-model="radius"
            >
              <option value="5"> 5 km </option>
              <option value="10"> 10 km </option>
              <option value="15"> 15 km </option>
              <option value="20"> 20 km </option>
              <option value="25"> 25 km </option>
              <option value="30"> 30 km </option>
              <option value="40"> 40 km </option>
              <option value="50"> 50 km </option>
            </select>
          </div>
        </div>
      </section>

      <section>
        <base-button>{{ $t("landingPageSubmitButton") }}</base-button>
      </section>

      <section v-if="zipcodeError">
        <div
          class="show-all-clients"
          v-if="!allLocationsShown"
        >
          <button
            type="button"
            @click.prevent="listAllLocations"
          >Toon alle locaties <span class="icon arrow-head-down"></span></button>
        </div>
      </section>
    </form>

    <form
      method="POST"
      action="/"
      @submit.prevent="redirectToLocation"
    >
      <section v-if="locations.length">
        <div
          class="loading"
          v-if="isLoading"
        >
          <ring-spinner v-if="isLoading"></ring-spinner>
        </div>

        <div
          class="form-control"
          v-else
        >
          <label v-if="!allLocationsShown">Kies een locatie in de buurt</label>
          <ul id="locations">
            <li
              v-for="location in locations"
              :key="location.id"
            >
              <input
                type="radio"
                name="location"
                :id="'location-' + location.id"
                :value="location.url"
                v-model="selectedLocation"
              >
              <label :for="'location-' + location.id">
                <span class="indicator"></span>
                <span class="location">{{ location.name }}</span> {{ location.address }}, {{ location.postal_code }}, {{ location.locality }}
                <br>
              </label>
            </li>
          </ul>
          <div
            class="show-all-clients"
            v-if="!allLocationsShown"
          >
            <p>Kies uit alle locaties</p>
            <button
              type="button"
              @click.prevent="listAllLocations"
            >Toon alle locaties <span class="icon arrow-head-down"></span></button>
          </div>
        </div>

        <alert
          mode="error with-icon"
          icon="error"
          v-if="locationError"
          role="img"
          aria-label="Foutmelding"
        >Kies een locatie in de buurt.</alert>
      </section>

      <section class="navigation-buttons">
        <router-button
          to="/vaccinatiecode"
          class="secondary"
        ><span class="icon arrow-head-left"></span> Vorige</router-button>
        <base-button :disabled="!selectedLocation">Volgende <span class="icon arrow-head-right"></span></base-button>
      </section>
    </form>
  </main>
</template>

<script>

import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';
import BaseButton from '@/SharedKernel/Atoms/Buttons/BaseButton.vue';
import RingSpinner from '@/SharedKernel/Atoms/Spinners/RingSpinner.vue';
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';

import Client from '@/Infrastracture/Client';
const ClientResource = new Client();

export default {
  name: 'EntryForm',
  components: {
    RouterButton,
    BaseButton,
    RingSpinner,
    Alert,
  },

  data () {
    return {
      zipcode: null,
      radius: 5,
      locations: [],
      selectedLocation: null,
      isLoading: false,
      zipcodeError: false,
      locationError: false,
      allLocationsShown: false,
    };
  },

  methods: {
    zipcodeFormat () {
      this.zipcode = this.zipcode.substring(0, 4);
      this.zipcode = this.zipcode.replace(/[^0-9]+/g, '');
    },
    getLocations () {
      if (!this.zipcode) {
        this.zipcodeError = 'Vul alstublieft uw postcode in.'
        return;
      }

      this.zipcode = this.zipcode.toUpperCase();

      const zipCodeRegex = new RegExp('^[1-9][0-9]{3}\\s?([a-zA-Z]{2})?$', 'g')

      if (zipCodeRegex.test(this.zipcode) !== true) {
        this.zipcodeError = 'Uw postcode is niet correct'
        return;
      }
      this.isLoading = true
      this.zipcodeError = '';
      this.locations = [];

      ClientResource.
        list(this.zipcode, this.radius)
        .then((data) => {
          this.allLocationsShown = false
          this.processFetchedClients(data)
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('error', error)
        });
    },

    listAllLocations () {
      ClientResource.
        listAll()
        .then((data) => {
          this.allLocationsShown = true
          this.zipcodeError = false;
          this.processFetchedClients(data)
        })
        .catch((error) => {
          this.isLoading = false
          console.log('error', error)
        });

    },

    processFetchedClients (data) {
      this.isLoading = false
      this.selectedLocation = null

      if (data.message) {
        this.zipcodeError = data.message
        return
      }

      if (data.length === 0) {
        this.zipcodeError = 'Geen locaties gevonden binnen de gekozen afstand.'
        return
      }
      let locations = data;

      const signedUrls = JSON.parse(localStorage.getItem('signedUrls'))

      const fetchedLocationsByPostalCode = [];
      if (this.locations.length) {
        this.locations.forEach((location) => {
          fetchedLocationsByPostalCode.push(location.postal_code)
        })
      }

      locations.forEach((location) => {
        if (fetchedLocationsByPostalCode.includes(location.postal_code) === false) {
          this.locations.push({
            id: location.id,
            url: signedUrls[location.id],
            name: location.name,
            address: location.address,
            postal_code: location.postal_code,
            locality: location.locality
          })
        }
      });
    },

    redirectToLocation () {
      if (!this.selectedLocation) {
        this.locationError = true;
        return;
      }
      window.location.href = this.selectedLocation;
    },
  },
};
</script>

<style scoped>
form {
  margin-bottom: 50px;
}
.form-control {
  margin-bottom: 20px;
}
.form-control:after {
  content: "";
  display: block;
  clear: both;
}
.form-control label {
  display: block;
  margin-bottom: 10px;
}

.selectbox {
  width: 120px;
}

#locations {
  padding: 0;
  margin: 0;
  list-style: none;
}
#locations li {
  position: relative;
  margin-bottom: 10px;
}
#locations input {
  position: absolute;
  opacity: 0;
  z-index: -9999;
}
#locations label {
  position: relative;
  display: block;
  padding: 10px 10px 10px 50px;
  border: 1px solid #01689a;
  background: #fff;
}
#locations label .indicator {
  position: absolute;
  top: 50%;
  left: 15px;
  height: 20px;
  width: 20px;
  border: 2px solid #000000;
  border-radius: 50%;
  margin-top: -10px;
}
#locations input:focus ~ label {
  position: relative;
  outline: 2px dotted #000;
  z-index: 1010;
  outline-offset: -1px;
  -webkit-box-shadow: 0 0 0 2px #fff;
  box-shadow: 0 0 0 2px #fff;
}
#locations :checked ~ label .indicator:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: auto;
  background: #01689a;
}
#locations label .location {
  font-weight: bold;
}

#zip_code {
  width: 120px;
  text-transform: uppercase;
}

.show-all-clients {
  margin-top: 30px;
}
.show-all-clients button {
  display: block;
  width: 100%;
  padding: 14px;
  border: 1px solid #01689a;
  background: #fff;
  color: #01689a;
  font-size: inherit;
}
.show-all-clients button .icon {
  font-size: 12px;
}
</style>
