<template>
  <div
    class="dialog"
    v-if="show"
  >
    <div class="window">
      <button
        class="close-dialog"
        @click.prevent="closeDialog"
      >
        <icon
          type="close"
          aria-label="Sluit melding"
        ></icon>
      </button>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/SharedKernel/Atoms/Icons/Icon.vue';

export default {
  components: { Icon },
  props: {
    show: {
      type: Boolean,
      required: false,
    }
  },
  computed: {
    displayDialog () {
      return this.display;
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
}

.dialog .window {
  position: relative;
  align-content: center;
  width: 100%;
  max-width: 580px;
  padding: 30px;
  background: #f3f3f3;
}

.dialog .close-dialog {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;
  padding: 0;
  border: 0;
  background: none;
}
</style>
