import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import maintenanceModule from './Modules/Maintenance/index';
import voucherCodeModule from './Modules/VoucherCode/index';
import healthCheckModule from './Modules/HealthCheck/index';
import WaitingListModule from './Modules/WaitingList/index';
import FaqDialogModule from './Modules/FaqDialog/index';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    maintenanceModule,
    voucherCodeModule,
    healthCheckModule,
    WaitingListModule,
    FaqDialogModule,
  },

  strict: debug,

  plugins: debug ? [createLogger()] : [],
});
