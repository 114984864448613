import axios from 'axios';

class Client {
  // eslint-disable-next-line class-methods-use-this
  list (origin, distance) {
    return axios({
      method: 'get',
      url: 'https://api.ziekenhuiscoronaprik.nl/v1/public/clients?origin=' + origin + '&distance=' + distance,
    })
      .then(function (response) {
        return response.data;
      });
  }

  listAll () {
    return axios({
      method: 'get',
      url: 'https://api.ziekenhuiscoronaprik.nl/v1/public/clients',
    })
      .then(function (response) {
        return response.data;
      });
  }
}
export { Client as default };
